<script>
/**
 * LOAD DEPENDENCIES
 * --------------------------------------------------------------------------------
 */
import {useStore} from "vuex";
import SvgIcon from '@/components/SvgIcon';
import PercentGraph from '@/components/PercentGraph';
import EmptyGraph from '@/components/EmptyGraph';

/**
 * VUE COMPONENT
 * --------------------------------------------------------------------------------
 */
export default {
  props : ['country', 'items', 'indices'],
  setup(props){
    const store  = useStore();
    const values = store.getters.getDataForObjectiveGraphs(props.indices, props.items, 2);
    const all    = store.state.indexGuide.filter(d => d.axis == 1 && d.objective == 2);

    /**
     * TEMPLATE ELEMENTS
     * --------------------------------------------------------------------------------
     **/
    return {
      getIcon : store.getters.getIcon,
      values,
      all
    }
  },

  /**
   * COMPONENTS
   * --------------------------------------------------------------------------------
   **/
  components : {
    SvgIcon,
    PercentGraph,
    EmptyGraph
  }
}
</script>
<template>
<div class="row pt-4 mt-4">
  <div class="col-12 pt-4 mt-4">
        <h2><strong>OBJETIVO 2</strong>: Cero nuevas infecciones por el VIH entre los niños y las madres con vida y saludables</h2>
        <div class="table">
        <table class="table objetivos">
          <tbody>
            <tr>
              <td rowspan="2">
                <div class="row mb-4">
                  <div class="col-1">
                    <svg-icon :icon="getIcon('gasto')" :hasFill="true" class="od_svg_icon icon_title"></svg-icon>
                  </div>
                  <div class="col-11">
                    Proporcionar medicamentos antirretrovirales a una mujer que vive con el VIH, ya sea antes de la concepción o durante el embarazo o el parto, puede reducir considerablemente el riesgo de la transmisión maternoinfantil. <br><br>
                    Esta intervención es más eficaz si se proporcionan medicamentos antirretrovirales durante el embarazo, el parto y la lactancia, y si se emplean prácticas de parto seguras y una lactancia más segura.
                  </div>
                </div>
              </td>
              <td>
                <div class="row">
                  <div class="col-1">
                    <svg-icon :icon="getIcon('personas')" :hasFill="true" class="od_svg_icon icon_title"></svg-icon>
                  </div>
                  <div class="col-11">
                  <strong>Compromiso 1 de la Reunión de Alto Nivel (High Level Meeting) 2016-2021 de la ONU</strong><br>
                  Eliminar los nuevos casos de infección por VIH en niños antes de 2020 y garantizar que 1,6 millones de niños tienen acceso al tratamiento para el VIH antes de 2018
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="row">
                  <div class="col-1">
                    <svg-icon :icon="getIcon('tratamiento')" :hasFill="true" class="od_svg_icon icon_title"></svg-icon>
                  </div>
                  <div class="col-11">
                  <strong>Objetivo de Desarrollo Sostenible  3</strong><br>
                  Buena salud y bienestar
                </div>
              </div>
              </td>
            </tr>
          </tbody>
        </table>
        </div>
      </div>

      <div v-for="index of all" :key="`tmp-${index.db_name}`">
        <!-- <percent-graph v-for="indice of values" :indice="indice" :country="country" :key="indice.name" /> -->
        <percent-graph v-if="values.find(d => index.db_name == d.column)" :indice="values.find(d => index.db_name == d.column)" :country="country" />
        <empty-graph v-else :indice="index" :country="country" />
      </div>



</div>

  
</template>